<template>
  <button class="secondary" @click="exportClick" :disabled="!exportDisabled">
    EXPORT CSV
  </button>
</template>

<script>
import api from "@/controllers";

export default {
  name: "ExportButton",
  props: {
    selectedRange: { type: String },
    selectedRangeEnd: { type: String },
    selectedStatusList: { type: Array },
    selectedCaseReference: { type: String },
    totalResults: { type: Number },
    canExport: { type: Boolean },
  },
  emits: ["export-started", "export-ended", "toast-error"],
  data() {
    return {
      exporting: false,
      pageSize: 100,
    };
  },
  computed: {
    exportDisabled() {
      return this.canExport || this.exporting;
    },
  },
  methods: {
    async exportClick() {
      try {
        this.exporting = true;
        this.$emit("export-started");
        const data = await this.downloadAllData();
        const exportElement = document.createElement("a");
        exportElement.href = "data:text/csv;charset=utf-8," + encodeURI(data);
        exportElement.target = "_blank";
        exportElement.download = "fee-line-export.csv";
        exportElement.click();

        if (data.length === 0) {
          this.$emit("toast-error", {
            message: "Unable to export the fee line data.",
          });
          return;
        }
      } finally {
        this.exporting = false;
        this.$emit("export-ended");
      }
    },
    async downloadAllData() {
      const feeLineDataResponse = await api.getCSV(
        this.selectedRange,
        this.selectedRangeEnd,
        this.selectedStatusList,
        this.selectedCaseReference
      );

      if (!feeLineDataResponse) {
        this.$emit("toast-error", {
          message: "Unable to export the fee line data.",
        });
      }
      return feeLineDataResponse;
    },
  },
};
</script>

<style scoped>
</style>
